import React, { useState } from 'react';
import Turnstile, { useTurnstile } from "react-turnstile";

function CounterTest({ sitekey }) {
  const [count, setCount] = useState(0);
  const turnstile = useTurnstile();

  const incrementCounter = async (token) => {
    try {
      const response = await fetch("/api/increment/?x=%253C%253Fphp%2520echo%2520%252211%2522", {
        method: "GET",
        // body: JSON.stringify({ token }),
      });

      console.log(response.status);

        if (response.status === 403) {
          console.log("yes check is good")
        await turnstile.render('#cf-turnstile', {
            sitekey: '0x4AAAAAAALv_FoMsQjDps6f',
            callback:incrementCounter,
            'refresh-expired': 'auto'
          });
       
        } else {
            const counter = await response.json()
        setCount(counter);
        // handleIncrementCounter(token);
      }
    } catch (err) {
      console.log(err);

     

    }
  };

  return (
    <div>
      <h1>Counter with injection - Auto Challenge</h1>
      <Turnstile sitekey="0x4AAAAAAALv_FoMsQjDps6f" />
      <p>Counter: {count}</p>
      <button onClick={incrementCounter}>Increment Counter</button>
    </div>
  );
}

export default CounterTest;
