import React, { useState } from 'react';
import Turnstile, { useTurnstile } from "react-turnstile";

function CounterAutoChallenge() {
  return (
    <div>
      <h1>Counter with injection - Auto Challenge</h1>
      <TurnstileWidget sitekey="0x4AAAAAAALv_FoMsQjDps6f" />
    </div>
  );
}

function TurnstileWidget({ sitekey }) {
  const [count, setCount] = useState(0);
  const turnstile = useTurnstile();

  const handleIncrementCounter = (token) => {
    fetch("/api/increment/?x=%253C%253Fphp%2520echo%2520%252211%2522", {
      method: "GET",
    //  body: JSON.stringify({ token }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // Handle error or reset the challenge and retry if needed
          turnstile.reset();
          handleVerify(token);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setCount(data.count);
      });
  };

  const handleVerify = (token) => {
    fetch("/api/increment/?x=%253C%253Fphp%2520echo%2520%252211%2522", {
      method: "GET",
      //body: JSON.stringify({ token }),
    }).then((response) => {
      if (!response.ok) {
        turnstile.reset();
      } else {
        handleIncrementCounter(token);
      }
    });
  };

  const handleButtonClick = () => {
    // Trigger the counter increment when the button is clicked
    handleIncrementCounter("your_token_here"); // Replace with the actual token obtained from Turnstile
  };

  return (
    <div>
      <Turnstile sitekey={sitekey} onVerify={handleVerify} />
      <p>Counter: {count}</p>
      <button onClick={handleButtonClick}>Increment Counter</button>
    </div>
  );
}

export default CounterAutoChallenge;
