import React, { useState } from 'react';
import Axios from 'axios';

function Counter() {
  const config = {
    headers:{
      "cf-trace-id": "001399143339153a:1399143339153a:0:3:1697515205:4ba86999a54e395dc59a58fba95ff670980c3d61f1ce8e1c1a873b263ddbf643"
    }
  };
  const [count, setCount] = useState(0);

  const incrementCounter = () => {
    // Make an XHR request to update the counter
  Axios.get('/api/increment/?x=%253C%253Fphp%2520echo%2520%252211%2522',config)
     // Axios.get('/api/increment')
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error('Error updating counter:', error);
      });
  };

  return (
    <div>
      <h1>Counter with injection: {count}</h1>
      <button onClick={incrementCounter}>Increment injection</button>
    </div>
  );
}

export default Counter;

